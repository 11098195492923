import service from '@/utils/request'

const url = '/api/v1/models'


export const updateModels = (data) => {
  return service({
    url: url,
    method: 'patch',
    data,
  })
}

export const addModels = (data) => {
  return service({
    url,
    method: 'post',
    data,
  })
}

export const deleteModels = (id) => {
  return service({
    url: url + '/' + id,
    method: 'delete',
  })
}

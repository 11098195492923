import service from '@/utils/request'

const url = '/api/v1/devdataunit'


export const updateDataUnit = (data) => {
  return service({
    url: url,
    method: 'patch',
    data,
  })
}

export const addDataUnit = (data) => {
  return service({
    url,
    method: 'post',
    data,
  })
}

export const deleteDataUnit = (id) => {
  return service({
    url: url + '/' + id,
    method: 'delete',
  })
}

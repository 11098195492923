<template>
  <div>
    <div class="gva-table-box">
      <div class="gva-btn-list">
        <el-button size="small" type="primary" icon="plus" @click="newModel">新增</el-button>
      </div>
      <el-table ref="multipleTable" style="width: 100%" tooltip-effect="dark" height="800" :data="tableData" row-key="id">
        <el-table-column align="left" label="id" prop="id" min-width="50" />
        <el-table-column align="left" label="类型" prop="model" min-width="80" />
        <el-table-column align="left" label="类型名称" prop="name" min-width="120" />
        <el-table-column align="left" label="数据类型"  min-width = "100">
          <template #default="scope"><div v-if="scope.row.dataFmt == null">-</div> <div v-else> {{ scope.row.dataFmt.name }}</div></template>
        </el-table-column>
        <el-table-column align="left" label="数据简称"  min-width = "80">
          <template #default="scope"><div v-if="scope.row.dataFmt == null">-</div> <div v-else> {{ scope.row.dataFmt.abbreviation }}</div></template>
        </el-table-column>
        <el-table-column align="left" label="时间间隔秒"  min-width = "80">
          <template #default="scope"><div v-if="scope.row.dataFmt == null">-</div> <div v-else> {{ scope.row.dataFmt.timeInterval }}</div></template>
        </el-table-column>
        <el-table-column align="left" label="是否物理设备" min-width = "80">
          <template #default="{row}">
            {{ row.countable ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column label="详情" type="expand" width = "100">
          <template #default="scope">
            <div v-if="scope.row.dataFmt" style="display: flex; flex-wrap: wrap; width: 1200px; left: 200px; position: relative">
              <el-tag
                  style="margin: 5px 20px 5px 20px;"
                  v-for="i in scope.row.dataFmt.units"
                  :key="i.id"
                  class="ml-2"
                  type="primary"
                  @click="editDataUnit(i)"
              >
                {{i.name}}
              </el-tag>
              <el-tag style="margin: 5px 20px 5px 20px;" type="success" @click="newDataUnit(scope.row.devDataId)">新增数据项</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作" min-width = "120">
          <template #default="scope">
            <el-button plain type="primary" icon="edit" size="small" class="table-button" @click="changeModel(scope.row)">变更</el-button>
            <el-button plain type="warning" icon="delete" size="small" class="table-button" @click="deleteRow(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="gva-pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="page"
          :page-size="pageSize"
          :page-sizes="[15, 30, 50, 100]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <el-dialog
      v-model="modelDialogVisible"
      :before-close="closeDialog"
      title="设备"
    >
      <el-form :model="ModelFormData" label-position="right" label-width="110px">
        <el-form-item label="类型">
          <el-input v-model.trim="ModelFormData.model"/>
        </el-form-item>
        <el-form-item label="类型名称">
          <el-input v-model.trim="ModelFormData.name"/>
        </el-form-item>
        <el-form-item>
          <el-radio-group v-model="ModelFormData.type">
            <el-radio :label="1">静态设备</el-radio>
            <el-radio :label="2">接地箱</el-radio>
            <el-radio :label="3">智能设备</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ModelFormData.type===3" label="数据类型">
          <el-select v-model="ModelFormData.devDataId" value-key="id" clearable>
            <el-option v-for="i in allDevDatas" :key="i.id" :label="i.name" :value="i.id"/>
          </el-select>
          <el-button v-show="ModelFormData.devDataId" @click="changeDevData">编辑</el-button>
          <el-button @click="newDevData">新增</el-button>
        </el-form-item>
        <el-form-item label="是否物理设备">
          <el-select v-model="ModelFormData.countable">
            <el-option :value="false" label="否" />
            <el-option :value="true" label="是" />
          </el-select>
        </el-form-item>
      </el-form>
        <el-dialog
            v-model="innerVisible"
            width="30%"
            title="数据类型"
            append-to-body
        >
          <el-form>
            <el-form-item label="数据类型">
              <el-input v-model.trim="DataFormData.name"/>
            </el-form-item>
            <el-form-item label="时间间隔">
              <el-input v-model.number="DataFormData.timeInterval"/>
            </el-form-item>
            <el-form-item label="简称">
              <el-input v-model.trim="DataFormData.abbreviation"/>
            </el-form-item>
            <el-popconfirm title="确定要删除吗？" @confirm="removeDevData(DataFormData.id)">
              <template #reference>
                <el-button type="danger" icon="delete">删除</el-button>
              </template>
            </el-popconfirm>
          </el-form>
          <template #footer>
            <div class="dialog-footer">
              <el-button size="small" @click="closeInner">取 消</el-button>
              <el-button size="small" type="primary" @click="enterInner">确 定</el-button>
            </div>
          </template>
        </el-dialog>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="small" @click="closeDialog">取 消</el-button>
          <el-button size="small" type="primary" @click="enterDialog">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <el-dialog v-model="showDataUnit" @close="cancelDataUnit">
    <el-form>
      <el-form-item label="关键字">
        <el-input v-model.trim="dataUnitForm.keyword" />
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model.trim="dataUnitForm.name" />
      </el-form-item>
      <el-form-item label="数据类型">
        <el-input v-model.trim="dataUnitForm.ValueType" />
      </el-form-item>
      <el-form-item label="单位">
        <el-input v-model.trim="dataUnitForm.unit" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="confirmDataUnit">确认</el-button>
        <el-button @click="showDataUnit=false">取消</el-button>
        <el-popconfirm title="确定要删除吗？" @confirm="delelteDataUnit">
          <template #reference>
            <el-button type="danger" icon="delete">删除</el-button>
          </template>
        </el-popconfirm>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "DevModels",
};
</script>

<script setup>
import {
  addDataUnit,
  updateDataUnit,
  deleteDataUnit,
} from "@/api/dataUnit.js";
import {
  updateModels,
  addModels,
  deleteModels,
} from "@/api/model.js";
import {
  getDevData,
  updateDevData,
  addDevData,
  deleteDevData,
} from "@/api/devData.js";

import service from "@/utils/request";
import { ElMessage, ElMessageBox } from "element-plus";
import { ref } from "vue";

// =========== 表格控制部分 ===========
const page = ref(1);
const total = ref(0);
const pageSize = ref(18);
const tableData = ref([]);

const showDataUnit = ref(false)
const dataUnitForm = ref({})
let changingDataUnit = false
function editDataUnit(unit) {
  dataUnitForm.value = {...unit}
  showDataUnit.value = true
  changingDataUnit = true
}
function cancelDataUnit() {
  dataUnitForm.value = {}
}
function delelteDataUnit() {
  deleteDataUnit(dataUnitForm.value.id)
      .then(thenFunc)
}
function newDataUnit(devDataId) {
  dataUnitForm.value = {devDataId}
  showDataUnit.value = true
  changingDataUnit = false
}
function confirmDataUnit() {
  if (changingDataUnit) {
    updateDataUnit(dataUnitForm.value)
        .then(thenFunc)
  } else {
    addDataUnit(dataUnitForm.value)
        .then(thenFunc)
  }
}
async function thenFunc(r) {
  if (r === 0) {
    ElMessage({
      type: 'success',
      message: r.msg
    })
  } else {
    ElMessage({
      type: 'warning',
      message: r.msg
    })
  }
  showDataUnit.value = false
  innerVisible.value = false
  return getTableData()
}

// 分页
const handleSizeChange = (val) => {
  pageSize.value = val;
  getTableData();
};

// 修改页面容量
const handleCurrentChange = (val) => {
  page.value = val;
  getTableData();
};

const allDevDatas = ref([])

// 查询
const getTableData = async () => {
  await service({
    url: '/api/v1/models?ext=true',
    method: "get"
  })
    .then((res) => {
      if (res.code === 0) {
        if (res.data.list) {
          tableData.value = res.data.list;
          total.value = res.data.total;
          page.value = res.data.page;
          pageSize.value = res.data.pageSize;
        } else {
          let list = res.data;
          let begin = (page.value - 1 ) * pageSize.value;
          let end = (page.value ) * pageSize.value;
          let resultList = list.slice(begin,end);
          tableData.value = resultList;
          total.value = res.data.length;
          // console.log("page",page)
        }
      }
    })
    .catch((e) => {
      console.log(e);
    });
  await getDevData().then((r)=>{
    if (r.code === 0) {
      allDevDatas.value = r.data.list
    }
  })
};

getTableData();

// 删除行
const deleteRow = (row) => {
  ElMessageBox.confirm("确定要删除吗?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    deleteDevModelsFunc(row);
  });
};

// 行为控制标记（弹窗内部需要增还是改）
let addingModel = false

const ModelFormData = ref({})
const DataFormData = ref({})

const changeModel = async (row) => {
  ModelFormData.value = {...row}
  DataFormData.value = {...row.dataFmt}
  addingModel = false
  modelDialogVisible.value = true
}

const deleteDevModelsFunc = async (row) => {
  deleteModels(row.id)
      .then(thenFunc)
}

const modelDialogVisible = ref(false)

const newModel = () => {
  addingModel = true
  modelDialogVisible.value = true
}

const closeDialog = () => {
  modelDialogVisible.value = false;
  ModelFormData.value = {}
}

const enterDialog = async () => {
  let modelService = updateModels
  if (addingModel) {
    modelService = addModels
  }
  modelService(ModelFormData.value).then(thenFunc)
}

const innerVisible = ref(false)
let addingData = false
function closeInner() {
  innerVisible.value = false
  DataFormData.value = {}
}
function newDevData() {
  DataFormData.value = {}
  addingData = true
  innerVisible.value = true
}
function changeDevData() {
  for (let i of allDevDatas.value) {
    if (i.id === ModelFormData.value.devDataId) {
      DataFormData.value = {...i}
    }
  }
  // DataFormData.value = {...i}
  addingData = false
  innerVisible.value = true
}
function enterInner() {
  let dataService = updateDevData
  if (addingData) {
    dataService = addDevData
  }
  dataService(DataFormData.value).then(
      (r)=>{
        if (r.code === 0) {
          thenFunc(r).then(
              ()=>{
                ModelFormData.value.devDataId = allDevDatas.value[allDevDatas.value.length - 1].id
              }
          )
        }
      }
  )
}
function removeDevData(id) {
  deleteDevData(id).then(
      (r)=>{
        if (r.code === 0) {
          ModelFormData.value.devDataId = undefined
          thenFunc(r)
        }
      }
  )
}

</script>

<style>
</style>

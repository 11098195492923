import service from '@/utils/request'

const url = '/api/v1/devdatas'

export const getDevData = () => {
  return service({
    url: url,
    method: 'get',
  })
}


export const updateDevData = (data) => {
  return service({
    url: url,
    method: 'patch',
    data,
  })
}

export const addDevData = (data) => {
  return service({
    url,
    method: 'post',
    data,
  })
}

export const deleteDevData = (id) => {
  return service({
    url: url + '/' + id,
    method: 'delete',
  })
}
